import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Hidden, Typography } from '@material-ui/core'

import { RectButton } from '../components'

import RootStore from '../src/stores/RootStore'

interface Props {
  store?: RootStore
}

@inject('store')
@observer
class Custom404 extends Component<Props> {
  componentDidMount = () => {
    if (window.location.href.includes('/ca?')) {
      const { routeHandler } = this.props.store!
      routeHandler.goToRootRoute()
    }
  }

  // Go back home
  goBackHome = () => {
    const { routeHandler } = this.props.store!
    routeHandler.goToRootRoute()
  }

  render() {
    const { i18n: { s } } = this.props.store!

    return (
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width={'100%'} flex={1} minHeight={'100%'}>

        <Hidden mdUp>
          <Box mt={'calc(50vh - 120px)'} />
        </Hidden>

        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'h4'}>{'404'}</Typography>
          <Box ml={2} mt={0.3}>
            <Typography variant={'h5'}>{s('| Page not found..')}</Typography>
          </Box>
        </Box>

        <Box mt={4} />

        <RectButton onClick={this.goBackHome} color={'primary'} variant={'contained'}>
          {s('Go to home')}
        </RectButton>
      </Box>
    )
  }
}

export default Custom404
